<template>
  <div>商品品分类</div>
</template>

<script>
export default {}
</script>

<style>
</style>
